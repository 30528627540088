<template>

    <!-- This example requires Tailwind CSS v2.0+ -->
<!--
  This example requires updating your template:

  ```
  <html class="h-full">
  <body class="h-full">
  ```
-->
<div class="bg-white px-4 sm:px-6 md:grid md:place-items-center lg:px-8 h-screen">
  <div class="max-w-max mx-auto grid place-items-center h-screen">
    <main class="sm:flex">
      <p class="text-4xl font-extrabold text-green-500 sm:text-5xl">Coming Soon</p>
      <div class="sm:ml-6">
        <div class="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Bulgaria Web Group Ltd.</h1>
        </div>

      </div>
    </main>
  </div>
</div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script>
